import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Link } from "@StarberryUtils"
import { graphql, navigate } from 'gatsby'
import classNames from 'classnames';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import axios from 'axios';
import SEO from "../components/seo";
import logoBlack from "../images/redbrik-new-logo.svg"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import BannerPage from "../components/ProperyDetails/PropertyBanner"
import DetailsPage from "../components/ProperyDetails/Details/Details"
import DetailsPageHead from "../components/ProperyDetails/Details/DetailsHead"
import LocRatingMap from '../components/map/loc-rating-map-area-guides';
//import Description from "../components/ProperyDetails/PropertyDescription/PropertyDescription"
import Calculator from "../components/ProperyDetails/Calculator/Calculator"
import Properties from "../components/ProperyDetails/SimilarProperties/SimilarProperties"
import Contact from "../components/Home/ContactUs/Contact-Property-Details"
import { defaultArea } from "@constants";
import { getPidFromUrl } from '@components/SearchResult/filter/constant'
import { buildingTypes } from '../components/common/site/utils';

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}


function getOffset( el ) {
  let _x = 0;
  let _y = 0;
  while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
  }
  return { top: _y, left: _x };
}

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){
    properties(where:{id: $id, publish: true}) {
      id
      title
      display_address
      address
      search_type
      status
      building
      price
      price_qualifier
      bedroom
      bathroom
      reception
      images
      floorplan
      virtual_tour
      office_crm_id
      imagetransforms
      latitude
      longitude
      accomadation_summary
      long_description
      brochure
      extra
      slug
      crm_id
    }
  }
`;

const PropertyDetailsTemplate = (props) => {

    const stickyRef = useRef();

    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let myid_filt = pageurl.split('?');
    let mypageurl = myid_filt[0];
    let property_slug = mypageurl.split('-');
    let id = property_slug[property_slug.length - 1];
    id = id.replace("/", "");

    const locationurl = typeof window !== 'undefined' ? window.location.pathname : '';
    let propertyslug = locationurl.split('/');
    const propurlSlug = propertyslug[2]?.split('-')?.slice(0, -1)?.join('-');

     // similar properties
      const [propItems, setPropItems] = useState([])
      const [render, setRender] = useState(false)
      const getitems = async url => {
        try {
          const { data } = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
            }})
          if (data && data.length > 0) {
            setPropItems(data)
          }
        } catch (error) {
          console.error(error)
        }
      }
      // similar

      // redirection search result page if property not found
      const handleNoProperty = () => {
          if (typeof window !== `undefined`) {
              let currentUrl = window.location.href;
              let redirectUrl = `/property-for-sale/in-${defaultArea.slug}/`
              if (currentUrl.match(/to-rent/))
                redirectUrl = `/property-to-rent/in-${defaultArea.slug}/`
              window.location.href = redirectUrl;
          }
      }

      useEffect(() => {
        // similar properties
        // $('head').append('<meta name="twitter:card" content="summary_large_image" />');
        // $('head').append('<meta name="twitter:title" content="Estate Agents in Shropshire, Staffordshire and West Midlands" />');
        const pageurl = typeof window !== 'undefined' ? window.location.href : ''
        let property_slug = pageurl.split('-');
        let similar_property_id = getPidFromUrl(property_slug[property_slug.length - 1]);

        let url = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/similar-properties?pid=" + similar_property_id;
        getitems(url)
        // similar properties
        // var lastScrollTop = 0;
        // window.addEventListener("scroll", () => {
        //   setScroll(window.scrollY > 60)
        //   var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        //   if (st > lastScrollTop) {
        //     // downscroll code
        //     setUpScroll(false)
        //   } else {
        //     // upscroll code
        //     setUpScroll(true)
        //   }
        //   lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        // })

        if(!render){
          setRender(true);
        }

        window.addEventListener("scroll", windowScroll);

        return () => window.removeEventListener("scroll", windowScroll)

      }, []);


    const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS, {
        variables: { id: id }
    });


    const windowScroll = () => {
      if (stickyRef.current?.classList) {
        const top = getOffset(stickyRef.current).top - 150;
        if(window.pageYOffset > top){
          stickyRef.current.classList.add('is-sticked');
        } else {
          stickyRef.current.classList.remove('is-sticked');
        }
      }
    }

    if (loading) return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img className="logo-white loader-logo" src={logoBlack}  alt="logo"/>
            </div>
            <div class="loader-section section-left"></div>
            <div class="loader-section section-right"></div>
          </div>
        </section>
        );
     
    if(property_details?.properties[0]?.slug != propurlSlug){
      if(property_details?.properties[0]?.search_type == "sales"){
         navigate(`/property-for-sale/in-${defaultArea.slug}/`)
      }else if(property_details?.properties[0]?.search_type == "lettings"){
        navigate(`/property-to-rent/in-${defaultArea.slug}/`)
      }
    }

    return (
        <React.Fragment>
            {
                property_details && property_details.properties && property_details.properties.length > 0 ? property_details.properties.map((data, i) => {

                // Get Map Details
                let mapItems = [];
                let mapItem = {};
                mapItem['lat']  = data.latitude
                mapItem['lng']  = data.longitude
                mapItem['id']  = data.id
                const mapMarkerImageShowFlag = 0;
                mapItems.push( mapItem );
                let title = data.title.toLowerCase().replace('bedroom', 'Bedroom').replaceAll('-', ' ');
                const metaTitle = title + ' in ' + data.display_address
                let propertyType = data?.building || 'property';
                propertyType = propertyType.replaceAll('-', ' ')
                if ((propertyType).toLowerCase() === 'other')
                    propertyType = 'property';

                // const metaDesc = 'Book a viewing for this ' + data.title + ' in ' + data.display_address + ' at ' + data.price + ' with Redbrik. Contact us for more information about this property.'
                //const metaDesc = `Find the details of ${propertyType.toLowerCase()} ${data?.status.toLowerCase()} with ${data?.bedroom} bedroom in ${data?.display_address} at £ ${data?.price}. Request a viewing to get assistance in knowing more about this ${propertyType}.`
                const metaDesc = `Find the details of ${title} in ${data?.display_address}. Request a viewing to get assistance in knowing more about this ${propertyType}.`
                return (
                        <div key={i}>
                          {(data?.slug == propurlSlug) &&
                            <div className="property-details-page">
                            <Header />
                            <SEO title={metaTitle} description={metaDesc} />
                            <BannerPage propertyData={data} />
                            <div className={classNames('property-detail-content-main')} ref={stickyRef}>
                              <DetailsPageHead propertyData={data} />                              
                              <DetailsPage propertyData={data} />                              
                              {data?.latitude && data?.longitude ? 
                              <div className="property-details-landing-map my-5" id="property-details-map">
                                  <LocRatingMap data={mapItems} hasTitle={true} />
                              </div> : ""}                              
                            </div>
                            {(data.search_type !== "lettings" && data.search_type !== "To Rent") && (
                                <Calculator price={data.price} />
                            )}
                            {
                              propItems.length > 0 &&
                                <Properties place={data.display_address} data={propItems} tag={data.department}/>
                            }
                            <Footer popular_search="property_details" propertydetails={data}/>
                            </div>
                          }
                        </div>
                    )
                }) : handleNoProperty()
            }
        </React.Fragment>
    )
}

export default PropertyDetailsTemplate
