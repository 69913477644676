import React, { useState } from "react";
import { sitename } from "@constants";
import { isEmpty, get } from "lodash";
import { Link } from "@StarberryUtils";
import { defaultArea, noImage } from "@constants";
import { Container, Row, Col } from "react-bootstrap";
import ModalTiny from "react-bootstrap/Modal"
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useLocation } from "@reach/router"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import { handleBackToListing } from "../common/site/utils";
import "./PropertyBanner.scss";
import { Helmet } from "react-helmet";
import PlayVideo from "../Play/property-details-video";
import BookViewForm from "../forms/book-a-viewing-form"
import ShareFrndForm from "../forms/send-friend-form"
import Negotiator from "./PropertyNegotiator"
import BookViewing from "./BookViewing"
import PriceComp from "@components/Property/Price"

import ScrollAnimation from "react-animate-on-scroll";
// Header component




const PropertyBanner = (props) => {
  // Property details image count
  const [indexed, setIndex] = useState(1);

  const afterChangeHandler = (d) => {
    setIndex(d + 1)
  }
  // Property details image count

  // Property images lightbox
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openPropertyImage = (e, ind) => {
    e.preventDefault();
    setPhotoIndex(ind);
    setIsOpen(true);
  }
  // Property images lightbox

  const lettingsPropList = ['ef223ba5-d706-4b8b-9b37-d7b6294ad0b4','f9896c0f-6caf-4f90-928e-e11289f88c19', '5ba34f04-02e9-4de6-9d36-1435506bfcfa', '04d5076a-79d9-4ce8-8ec4-e7114917bc61']

  const [modalSendfrndformOpen, setSendfrndformOpen] = React.useState(false);
  const openSendfrndformModal = () => {
    setSendfrndformOpen(true);
  }
  const closeSendfrndformModal = () => {
    setSendfrndformOpen(false);
  }

  const [modalSharefrndformOpen, setSharefrndformOpen] = React.useState(false);
  const openSharefrndformModal = () => {
    setSharefrndformOpen(true);
  }
  const closeSharefrndformModal = () => {
    setSharefrndformOpen(false);
  }

  const [isPlay, setPlay] = useState(false);
  const [primaryPhone, setPrimaryPhone] = useState(null);


  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1402,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 377,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let propertyImages = props.propertyData.images
  if (isEmpty(propertyImages)) {
    propertyImages = [
      {
        srcUrl: noImage,
        url: noImage
      }
    ]
  }

  // ggfx
  let processedImages = JSON.stringify({});
  if (props.propertyData?.imagetransforms?.images_Transforms) {
    processedImages = props.propertyData.imagetransforms.images_Transforms;
  }
  // ggfx

  // Property details images lightbox
  var propertyLightImages = propertyImages && propertyImages.map(img => img.srcUrl);
  // Property details images lightbox

  //
  let uriStr = "";
  if (props.propertyData.search_type === "sales") {
    uriStr = `/property-for-sale/in-${defaultArea.slug}/`
  } else if (props.propertyData.search_type === "lettings") {
    uriStr = `/property-to-rent/in-${defaultArea.slug}/`
  }

  if (props.propertyData.status === "Sold STC") {
    uriStr = "/sell/sold-gallery/"
  }
  // else if(props.propertyData.status === "To Let") {
  //   uriStr = "/landlords/let-gallery"
  // }
  //

  // Social share
  const location = useLocation();

  const shareurl = typeof window !== 'undefined' ? window.location.href : ''
  const viewingBookingUrl = get(props, 'propertyData.extra.viewing_booking_url', false)

  const [Shareicons, setShareicons] = React.useState(false);

  const openShareicons = () => {
    setShareicons(true);
    if (Shareicons === true) {
      setShareicons(false);
    }
  }

  const [modalBookViewformOpen, setBookViewingformOpen] = React.useState(false);

  const openBookViewformModal = () => {
    setBookViewingformOpen(true);
  }

  const holofy_Video = props?.propertyData?.virtual_tour?.includes("holofy") ? true : false;

  const trackerShare = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Share - social icons',
      'formType': event + ' - ' + shareurl,
      'formId': 'Share',
      'formName': 'Share',
      'formLabel': 'Share'
    });
  }
  // Social share

  var img_1 = noImage;
  if (propertyImages.length) {
    img_1 = propertyImages[0].srcUrl
  }


  let url_one = ''
  let photoplans = false

  if (props.propertyData.virtual_tour) {
    url_one = String(props.propertyData.virtual_tour)
    photoplans = (url_one.indexOf("photoplans.planup") > -1 || url_one.indexOf("holofy.io")  > -1)
  }

  React.useEffect(() => {
    var script_tag = document.createElement('script');
    script_tag.type = 'text/javascript';
    script_tag.text = `
    (function() {
      window.skillStore = {
        clientId: "rR6B6Joja"
      };
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("body");
      s1.async = true;
      s1.src ="https://videos.theskill.store/index.js";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0[0].appendChild(s1);
      var l1 = document.createElement("link"),
        l2 = document.createElement("link"),
        l0 = document.getElementsByTagName("head");
      l1.rel = "stylesheet";
      l1.type = "text/css";
      l1.href ="https://videos.theskill.store/index.css";
      l0[0].appendChild(l1);
    })()
    `;
    document.getElementById("___gatsby").appendChild(script_tag)
  });

  return (
    <React.Fragment>
      <section className="banner-slider ">
        {/* <Helmet>
          {<script type="text/javascript">

            var script_tag = document.createElement('script');
            script_tag.type = 'text/javascript';
            script_tag.text = '

            {` (function() {"{"}
            window.skillStore = {"{"}
            clientId: "rR6B6Joja"
            {"}"}; var s1 = document.createElement("script"), s0 =
            document.getElementsByTagName("body"); s1.async = true; s1.src
            ="https://videos.theskill.store/index.js"; s1.charset = "UTF-8";
            s1.setAttribute("crossorigin", "*"); s0[0].appendChild(s1); var l1 =
            document.createElement("link"), l2 = document.createElement("link"), l0 =
            document.getElementsByTagName("head"); l1.rel = "stylesheet"; l1.type =
            "text/css"; l1.href ="https://videos.theskill.store/index.css";
            l0[0].appendChild(l1);
            {"}"})();`}

            ';
            document.getElementById("___gatsby").appendChild(script_tag)
          </script>}

        </Helmet> */}

        <Container>
          <Row>
            <Col lg={12}>
              <div className="sm-info back-to-search-result">
                <a href="javascript:void(0)" onClick={(e) => { e.preventDefault(); handleBackToListing(props.propertyData) }}>
                  <i className="icon-back"></i>
                  Back to Search Results
                </a>
                <a className="share-link" href="javascript:void(0)" onClick={openShareicons}>
                  <i className="icon-share"></i>
                  Share this Property
                </a>
                {Shareicons &&
                  <div className="social-share-wrapper">
                    <div onClick={openShareicons} className="property-share-social-icons details-top">
                      <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                        <FacebookIcon size={32} round={false} borderRadius={`10`} />
                      </FacebookShareButton>
                      <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                        <TwitterIcon size={32} round={false} borderRadius={`10`} />
                      </TwitterShareButton>
                      <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                        <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                      </LinkedinShareButton>
                      <WhatsappShareButton onClick={() => trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                        <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                      </WhatsappShareButton>
                      <EmailShareButton url='#' onClick={() => { openSharefrndformModal(); trackerShare('SendToFriend') }} className="my-share-button email-share">
                        <EmailIcon size={32} round={false} borderRadius={`10`} />
                      </EmailShareButton>
                    </div>
                  </div>
                }
              </div>
            </Col>
          </Row>
        </Container>
        <div className="slider-wrapper">
          <div className="img-wrapper">
            <Slider {...settings}
              afterChange={
                v => afterChangeHandler(v)
              }
            >
              {
                propertyImages && propertyImages.map((node, i) => {
                  let processedImages = JSON.stringify({});
                  if (props.propertyData?.imagetransforms?.images_Transforms) {
                    processedImages = props.propertyData.imagetransforms.images_Transforms;
                  }

                  return (
                    <div className="banner-img img-zoom" onClick={(e) => openPropertyImage(e, 0)}>
                      <ImageTransform
                        imagesources={node.url}
                        renderer="srcSet"
                        imagename="property.images.detail"
                        attr={{ alt: `${props.propertyData?.display_address} - ${sitename}`, class: '' }}
                        imagetransformresult={processedImages}
                        id={props.propertyData.id}
                      />
                      {/* { holofy_Video == false ?
                        <>
                          {props?.propertyData?.virtual_tour && i == 0 &&
                          <div className="video-buttons ">

                            {photoplans == true ? <a href={props.propertyData.virtual_tour} target="_blank" className="photoplan"><strong className="video-btn"><i className="icon-video"></i></strong></a> :
                              <>{(props.propertyData.virtual_tour ? ((props.propertyData.virtual_tour.length > 0) ? <PlayVideo url={props.propertyData.virtual_tour} className="video-btn"><i className="icon-video"></i></PlayVideo> : '') : '')}</>
                            }


                          </div>
                          }
                        </>
                        : ''
                      } */}
                    </div>
                  )
                })
              }
            </Slider>
            <div className="property-slider-count-wrapper d-flex align-items-center justify-content-center">
              <ul className="list-inline d-flex align-items-center" onClick={(e) => openPropertyImage(e, 0)}>
                <li className="list-inline-item">
                  <a href="javascript:void(0)" onClick={(e) => openPropertyImage(e, 0)}><i className="icon-gallery"></i></a>
                </li>
                <li className="list-inline-item">{indexed}/{propertyLightImages.length}</li>
              </ul>
              {props?.propertyData?.virtual_tour &&
                <ul className="list-inline d-flex align-items-center">
                  <li className="list-inline-item">
                    {photoplans == true ? <a href={props.propertyData.virtual_tour} target="_blank" className="photoplan"><i className="icon-video-gallery"></i></a> :
                      <>{(props.propertyData.virtual_tour ? ((props.propertyData.virtual_tour.length > 0) ? <PlayVideo url={props.propertyData.virtual_tour} className="video-btn"><i className="icon-video-gallery"></i></PlayVideo> : '') : '')}</>
                    }
                  </li>
                  <li className="list-inline-item">
                    {photoplans == true ? <a href={props.propertyData.virtual_tour} target="_blank" className="video-text">Video</a> :
                      <>{(props.propertyData.virtual_tour ? ((props.propertyData.virtual_tour.length > 0) ? <PlayVideo url={props.propertyData.virtual_tour} className="video-text">Video</PlayVideo> : '') : '')}</>
                    }
                  </li>
                </ul>
              }

            </div>
            {props.propertyData.status === "Sold STC" ? <div className="flag">SOLD STC</div> : ''}
            {props.propertyData.status === "Let Agreed" ? <div className="flag">LET AGREED</div> : ''}
          </div>

          {/* Property image lightbox */}
          {isOpen && (
            <Lightbox
              mainSrc={propertyLightImages[photoIndex]}
              nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
              prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
              }
            />
          )}
          {/* Property image lightbox */}

          <div className="banner-card">
            <div className="banner-card-content">
              <div className="banner-content">
                <h1>{props.propertyData && props.propertyData.display_address}</h1>
                <span className="sub-title">{props.propertyData.title} in {props.propertyData.address.town}</span>
                <span className="sm-text">
                  <PriceComp {...props.propertyData} className="sm-text" />
                </span>
              </div>
              <div className="card-info">
              {
                  props.propertyData.building && props.propertyData?.building.length > 0 &&
                  <span className="details building">
                    <span className="details-icon">
                      <Link href="javascript:void(0)">
                        {props.propertyData.building.replace(/-/g, " ")}
                      </Link>
                    </span>
                  </span>
              }
              {
                  props.propertyData.bedroom &&
                  <span className="details">
                    <span className="details-icon">
                      <Link href="javascript:void(0)">
                        <i className="icon-chair"></i> {props.propertyData.bedroom}
                      </Link>
                    </span>
                  </span>
                }
                {
                  props.propertyData.bathroom &&
                  <span className="details">
                    <span className="details-icon">
                      <Link href="javascript:void(0)">
                        <i className="icon-bathroom"></i> {props.propertyData.bathroom}
                      </Link>
                    </span>
                  </span>
                }
                {
                  props.propertyData.reception &&
                  <span className="details">
                    <span className="details-icon">
                      <Link href="javascript:void(0)">
                        <i className="icon-bedroom"></i> {props.propertyData.reception}
                      </Link>
                    </span>
                  </span>
                }                
              </div>
              <div className="btn-wrapper social-share-wrapper">
                {viewingBookingUrl ? (
                  <a href={viewingBookingUrl} target="_blank" className="btn btn_black btn-viewing">
                    <i className="icon-view-box"></i> <span className="desktop">Book a Viewing</span><span className="mobile">Book a Viewing</span>
                  </a>
                ) : (
                  <a href="javascript:void(0)" className="btn btn-viewing btn_black" onClick={openSendfrndformModal}>
                    <i className="icon-view-box"></i>
                    <span className="desktop">Book a Viewing</span><span className="mobile">Book a Viewing</span>
                  </a>
                )}

                {primaryPhone && props.propertyData.search_type === "sales" &&
                  <a href={`tel:${primaryPhone}`} className="btn btn_black btn-viewing">
                    <i className="icon-phone"></i> <span className="desktop">{primaryPhone}</span><span className="mobile">Call</span>
                  </a>
                }
                {props.propertyData.search_type === "lettings" && lettingsPropList.includes(props.propertyData.crm_id) === true && 
                  <a href={`tel:01246380414`} className="btn btn_black btn-viewing">
                  <i className="icon-phone"></i> <span className="desktop">01246 380414</span><span className="mobile">Call</span>
                  </a>
                }
                {props.propertyData.search_type === "lettings" && lettingsPropList.includes(props.propertyData.crm_id) ===false &&
                  <a href={`tel:01143610140`} className="btn btn_black btn-viewing">
                  <i className="icon-phone"></i> <span className="desktop">0114 361 0140</span><span className="mobile">Call</span>
                  </a>
              }
              </div>
              <Negotiator id={props.propertyData.office_crm_id} search_type={props.propertyData.search_type} setPrimaryPhone={setPrimaryPhone} />
            </div>
          </div>

        </div>


        <ModalTiny show={modalSendfrndformOpen} onHide={closeSendfrndformModal} className="getin-touch book-a-viewing-modal">
          <ModalTiny.Header closeButton className="contact-close-btn">
            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Book a Viewing</h2></ModalTiny.Title>
          </ModalTiny.Header>

          <ModalTiny.Body className="event-section">
            {props.propertyData.office_crm_id ?
              <BookViewing id={props.propertyData.office_crm_id} property_img={img_1} page_url={shareurl} property_title={props.propertyData.display_address} type={props.propertyData.search_type} /> :
              <BookViewForm property_img={img_1} page_url={shareurl} property_title={props.propertyData.display_address} />}
          </ModalTiny.Body>

        </ModalTiny>


        <ModalTiny show={modalSharefrndformOpen} onHide={closeSharefrndformModal} className="getin-touch book-a-viewing-modal">
          <ModalTiny.Header closeButton className="contact-close-btn">
            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">SHARE TO A FRIEND</h2></ModalTiny.Title>
          </ModalTiny.Header>

          <ModalTiny.Body className="event-section">
            <ShareFrndForm property_img={img_1} page_url={shareurl} property_title={props.propertyData.display_address} />
          </ModalTiny.Body>

        </ModalTiny>

      </section>
    </React.Fragment>
  );
};

export default PropertyBanner;
