import React, { useState, useEffect } from "react";
import { get } from 'lodash';
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import ModalTiny from "react-bootstrap/Modal"
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import $ from "jquery";
import { useLocation } from "@reach/router"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import PlayVideo from "../../Play/property-details-video";
import ShareFrndForm from "../../forms/send-friend-form"
import BookViewForm from "../../forms/book-a-viewing-form"
import BookViewing from "../BookViewing"
import NegNumber from "../PropertyNegotiatorNumber"
import "./Details.scss";
import ScrollAnimation from 'react-animate-on-scroll';
const Soon = 'https://ggfx-redbrik.s3.eu-west-2.amazonaws.com/i.prod/coming_soon_large_e7f7a501b9.jpg'
// Header component

const Details = (props) => {

    // Floorplan slider
    const [isPlay, setPlay] = useState(false);
    const settings = {
      dots: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      arrows: true,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 1402,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },

        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 377,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const propertyImages = props.propertyData.images

    const [modalSharefrndformOpen,setSharefrndformOpen] = React.useState(false);
    const openSharefrndformModal = () => {
        setSharefrndformOpen(true);
    }
    const closeSharefrndformModal = () => {
        setSharefrndformOpen(false);
    }

    const floorplanImages = props.propertyData.floorplan
    // Floorplan slider

    // ggfx
    let processedImages = JSON.stringify({});
    if (props.propertyData?.imagetransforms?.images_Transforms) {
        processedImages = props.propertyData.imagetransforms.images_Transforms;
    }
    // ggfx

    // Property floorplan images lightbox
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const openPropertyImage = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }

    var propertyLightImagesFloorplan = floorplanImages && floorplanImages.map(img => img.url);
    // Property floorplan images lightbox

    // link to scroll
    const handleLinkTo = (e, target) => {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $(target).offset().top - 140//document.getElementById('pageheader').offsetHeight
        }, 1000);
    }

    useEffect(() => {
        var propertySelector = '.property-details-menu-wrapper li a';

        $(propertySelector).on('click', function(){
            $(propertySelector).removeClass('active');
            $(this).addClass('active');
        });

        // $(".propertyFeatures").click(function() {
        //     $('html, body').animate({
        //         scrollTop: $("#key-features").offset().top-140
        //     }, 1000);
        // })
        //
        // $(".propertyDetails").click(function() {
        //     $('html, body').animate({
        //         scrollTop: $("#property-details-description").offset().top-140
        //     }, 1000);
        // })
        //
        // $(".propertyMap").click(function() {
        //     $('html, body').animate({
        //         scrollTop: $("#property-details-map").offset().top - 140
        //     }, 1000);
        // })
    },[]);

    // Social share
    const location = useLocation();

    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    const viewingBookingUrl = get(props, 'propertyData.extra.viewing_booking_url', false)

    const [Shareicons,setShareicons] = React.useState(false);

    const openShareicons = () => {
        setShareicons(true);
        if(Shareicons === true) {
        setShareicons(false);
        }
    }

    const [modalSendfrndformOpen,setSendfrndformOpen] = React.useState(false);
    const openSendfrndformModal = () => {
      setSendfrndformOpen(true);
    }
    const closeSendfrndformModal = () => {
      setSendfrndformOpen(false);
    }

    const [modalBookViewformOpen,setBookViewingformOpen] = React.useState(false);

    const openBookViewformModal = () => {
        setBookViewingformOpen(true);
    }

    const holofy_Video = props?.propertyData?.virtual_tour?.includes("holofy") ? true : false;

    const trackerShare = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'Share - social icons',
        'formType': event + ' - ' +shareurl,
        'formId': 'Share',
        'formName': 'Share',
        'formLabel': 'Share'
        });
    }
    // Social share
    var img_1 = Soon;
    if(propertyImages.length) {
        img_1 = propertyImages[0].srcUrl
      }



  let url_one = ''
  let photoplans = false

  if(props.propertyData.virtual_tour) {
    url_one = String(props.propertyData.virtual_tour)
    photoplans = (url_one.indexOf("photoplans.planup") > -1 || url_one.indexOf("holofy.io")  > -1)
  }

    return (
        <React.Fragment>

                <div className="details-section sticky-header">
                    <Container>
                        <Row>
                            <Col lg={7} md={12}>
                                <ul className="list-inline property-details-menu-wrapper">
                                    {
                                        props.propertyData.accomadation_summary.length > 0 &&
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0)" onClick={(e) => handleLinkTo(e, '#key-features')} className="active propertyFeatures">Features</a>
                                        </li>
                                    }
                                    {
                                        props.propertyData.long_description &&
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0)" onClick={(e) => handleLinkTo(e, '#property-details-description')} className="propertyDetails">Description</a>
                                        </li>
                                    }
                                    {  props.propertyData.virtual_tour ?
                                    <>
                                        {
                                            props.propertyData.virtual_tour &&
                                            <li className="list-inline-item">
                                                {photoplans == true ? <a href={props.propertyData.virtual_tour} target="_blank" className="photoplan">Video</a> :
                                                <>{(props.propertyData.virtual_tour ? ((props.propertyData.virtual_tour.length > 0) ? <PlayVideo url={props.propertyData.virtual_tour} ><a href="javascript:void(0)">Video</a></PlayVideo> : ''):'')}</>
                                                }
                                            </li>
                                        }
                                    </>
                                    : ''
                                    }
                                    {
                                        props.propertyData.latitude &&
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0)" onClick={(e) => handleLinkTo(e, '#property-details-map')} className="propertyMap">Map</a>
                                        </li>
                                    }
                                    {
                                        floorplanImages &&
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0)" onClick={(e) => openPropertyImage(e, 0)} className="propertyFloorplan">Floorplan</a>
                                        </li>
                                    }
                                    {props.propertyData?.brochure && (
                                        <li className="list-inline-item">
                                            <a href={props.propertyData?.brochure} target="_blank" className="">Brochure</a>
                                        </li>
                                    )}
                                </ul>
                            </Col>
                            <Col sm={5} className="d-none d-lg-block">
                                <ul className="stick-btns">
                                    <li className="d-none d-xl-block">
                                        <NegNumber id={props.propertyData.office_crm_id} />
                                    </li>
                                    <li>
                                        {viewingBookingUrl ? (
                                            <a href={viewingBookingUrl} target="_blank" className="btn btn_black">Book a Viewing</a>
                                        ) : (
                                            <a href="javascript:void(0)" className="btn btn_black" onClick={openSendfrndformModal}>Book a Viewing</a>
                                        )}
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>

                    <div className="separation-line"></div>

                </div>

                {/* Property floorplan image lightbox */}
                {isOpen && (
                    <Lightbox
                        mainSrc={propertyLightImagesFloorplan[photoIndex]}
                        nextSrc={floorplanImages.length > 1 ? propertyLightImagesFloorplan[(photoIndex + 1) % propertyLightImagesFloorplan.length] : undefined}
                        prevSrc={floorplanImages.length > 1 ? propertyLightImagesFloorplan[(photoIndex + propertyLightImagesFloorplan.length - 1) % propertyLightImagesFloorplan.length] : undefined}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + propertyLightImagesFloorplan.length - 1) % propertyLightImagesFloorplan.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % propertyLightImagesFloorplan.length)
                        }
                    />
                )}
                {/* Property floorplan image lightbox */}


        <ModalTiny show={modalSendfrndformOpen} onHide={closeSendfrndformModal} className="getin-touch book-a-viewing-modal">
          <ModalTiny.Header closeButton className="contact-close-btn">
            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Book a Viewing</h2></ModalTiny.Title>
          </ModalTiny.Header>

          <ModalTiny.Body className="event-section">
            {props.propertyData.office_crm_id ?
            <BookViewing id={props.propertyData.office_crm_id} property_img={img_1} page_url={shareurl} property_title={props.propertyData.display_address} type={props.propertyData.search_type}/> :
            <BookViewForm property_img={img_1} page_url={shareurl} property_title={props.propertyData.display_address}/> }
          </ModalTiny.Body>

          </ModalTiny>

          <ModalTiny show={modalSharefrndformOpen} onHide={closeSharefrndformModal} className="getin-touch book-a-viewing-modal">
          <ModalTiny.Header closeButton className="contact-close-btn">
            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">SHARE TO A FRIEND</h2></ModalTiny.Title>
          </ModalTiny.Header>

          <ModalTiny.Body className="event-section">
            <ShareFrndForm property_img={img_1} page_url={shareurl} property_title={props.propertyData.display_address}/>
          </ModalTiny.Body>

          </ModalTiny>
        </React.Fragment>
    );
};

export default Details;
