import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"


const NEGOTIATOR = gql`
query GETNEG($URL: String!){
    offices(where:{CRM_ID: $URL}){
        Primary_Email
        Primary_Phone
    }
}
`;

function Negotiator(props) {


	const { loading, error, data } = useQuery(NEGOTIATOR, {
        variables: { URL: props.id }
      });

      if (loading) return (
        <p>loading...</p>
    )

    return (
            <>
            {data.offices && data.offices.map((neg, index) => {  
                return (
                    <>
                    <a href={`tel:${neg.Primary_Phone}`} className="tel">
                        Call <span>{neg.Primary_Phone}</span>
                    </a>
                    </>
                )
            })}
            </>
    )
}

export default Negotiator;